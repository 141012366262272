<template>
  <div class="content_body ReportCustomerArrearsDetail" style="padding:0px" v-loading="loading">
    <!-- 筛选 -->
    <div class="nav_header" style="padding:15px">
      <el-form :inline="true" size="small" :model="searchArrearsData" @submit.native.prevent>
        <el-form-item label="顾客姓名/编号">
          <el-input v-model="searchArrearsData.Name" clearable @keyup.enter.native="handleArrearsSearch"
            @clear="handleArrearsSearch" placeholder="请输入顾客姓名/编号"></el-input>
        </el-form-item>
        <el-form-item v-if="storeEntityList.length" label="所属门店">
          <el-select v-model="searchArrearsData.EntityID" clearable filterable placeholder="请选择门店"
            :default-first-option="true" @change="handleArrearsSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="EntityAllEntity.length>1" label="开单门店">
          <el-select v-model="searchArrearsData.BuyEntityID" clearable filterable placeholder="请选择门店"
            :default-first-option="true" @change="handleArrearsSearch">
            <el-option v-for="item in EntityAllEntity" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单时间">
          <el-date-picker v-model="searchArrearsData.QueryDate" unlink-panels type="daterange" range-separator="至"
            value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleArrearsSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select v-model="searchArrearsData.GoodsTypeName" clearable filterable placeholder="请选择卡类型"
            :default-first-option="true" @change="handleArrearsSearch">
            <el-option label="项目" value="项目"></el-option>
            <el-option label="储值卡" value="储值卡"></el-option>
            <el-option label="时效卡" value="时效卡"></el-option>
            <el-option label="通用次卡" value="通用次卡"></el-option>
            <el-option label="套餐卡" value="套餐卡"></el-option>
            <el-option label="产品" value="产品"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类别">
          <el-input v-model="searchArrearsData.CategoryName" clearable @keyup.enter.native="handleArrearsSearch"
            @clear="handleArrearsSearch" placeholder="请输入商品类别"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="searchArrearsData.GoodsName" clearable @keyup.enter.native="handleArrearsSearch"
            @clear="handleArrearsSearch" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleArrearsSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="ArrearsExport" type="primary" size="small" :loading="downloadLoading"
            @click="downloadArrearsExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-table size="small" show-summary :summary-method="getArrearsDetailListSummaries" :data="result.list"
      v-loading="ArrearsDetailLoading">
      <el-table-column prop="Name" label="顾客姓名"></el-table-column>
      <el-table-column prop="PhoneNumber" label="手机号"></el-table-column>
      <el-table-column prop="EntityName" label="所属门店"></el-table-column>
      <el-table-column prop="LevelName" label="顾客等级"></el-table-column>
      <el-table-column prop="BillID" label="订单编号"></el-table-column>
      <el-table-column prop="BuyDate" label="欠款时间"></el-table-column>
      <el-table-column prop="BuyEntityName" label="开单门店"></el-table-column>
      <el-table-column prop="EmployeeName" label="开单人"></el-table-column>
      <el-table-column prop="GoodsTypeName" label="商品类型"></el-table-column>
      <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
      <el-table-column prop="GoodsName" label="商品名称"></el-table-column>
      <el-table-column prop="PackageCardName" label="所属套餐卡"></el-table-column>
      <el-table-column prop="TotalAmount" align="right" label="订单金额">
        <template slot-scope="scope">
          {{scope.row.TotalAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="PayAmount" align="right" label="首次支付金额">
        <template slot-scope="scope">
          {{scope.row.PayAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SavingCardDeductionAmount" align="right" label="首次卡扣">
        <template slot-scope="scope">
          {{scope.row.SavingCardDeductionAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SavingCardDeductionLargessAmount" align="right" label="首次赠卡扣">
        <template slot-scope="scope">
          {{scope.row.SavingCardDeductionLargessAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="OriginMoney" align="right" label="期初欠款金额">
        <template slot-scope="scope">
          {{scope.row.OriginMoney | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="FillingMoney" align="right" label="已补款金额">
        <template slot-scope="scope">
          {{scope.row.FillingMoney | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="ArrearAmount" align="right" label="剩余欠款金额">
        <template slot-scope="scope">
          {{scope.row.ArrearAmount | NumFormat}}
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="ArrearsPaginations.total > 0" @current-change="handleArrearsPageChange"
        :current-page.sync="ArrearsPaginations.page" :page-size="ArrearsPaginations.page_size"
        :layout="ArrearsPaginations.layout" :total="ArrearsPaginations.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import API from "@/api/Report/Customer/arrearsDetail";
import EntityAPI from "@/api/Report/Common/entity";
import BuyEntityAPI from "@/api/Report/Customer/accountStatistics";
import permission from "@/components/js/permission.js";
import dateTime from "@/components/js/date";
export default {
  name: "ReportCustomerArrearsDetail",

  components: {},

  directives: {},

  data() {
    return {
      loading: false,
      storeEntityList: [], //所属门店列表
      EntityAllEntity: [], //开单门店
      //搜索条件
      searchArrearsData: {
        Name: "",
        EntityID: "",
        BuyEntityID: null,
        QueryDate: [],
        GoodsTypeName: null,
        GoodsName: null,
        CategoryName: null,
      },
      // 搜索结果
      result: {
        totalForm: {},
        list: [],
      },
      saleTreatDetailExport: true,
      downloadLoading: false,
      ArrearsDetailLoading: false,
      ArrearsExport: false, //导出权限
      ArrearsPaginations: {
        page: 1, // 当前位于哪页
        total: 20, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },
  created() {
    this.searchArrearsData.QueryDate = [
      dateTime.formatDate.format(new Date(), "YYYY-MM-DD"),
      dateTime.formatDate.format(new Date(), "YYYY-MM-DD"),
    ];
  },
  mounted() {
    const that = this;
    //   导出权限
    that.ArrearsExport = permission.permission(
      that.$route.meta.Permission,
      "Report-Customer-ArrearsDetail-Export"
    );
    //获得当前用户下的权限门店
    that.getstoreEntityList();
    // 获取开单门店
    that.getEntityAllEntity();
    // 搜索
    that.handleArrearsSearch();
  },

  methods: {
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取开单门店
    getEntityAllEntity() {
      var that = this;
      BuyEntityAPI.allEntity()
        .then((res) => {
          if (res.StateCode == 200) {
            that.EntityAllEntity = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 搜索
    handleArrearsSearch() {
      var that = this;
      that.ArrearsPaginations.page = 1;
      that.getArrearsList();
    },
    // 获取欠款明细列表
    getArrearsList() {
      const that = this;
      if (!that.searchArrearsData.QueryDate) return;
      that.ArrearsDetailLoading = true;
      const param = that.searchArrearsData;
      const params = {
        Name: param.Name,
        EntityID: param.EntityID,
        BuyEntityID: param.BuyEntityID,
        StartTime: param.QueryDate[0],
        EndTime: param.QueryDate[1],
        GoodsTypeName: param.GoodsTypeName,
        GoodsName: param.GoodsName,
        CategoryName: param.CategoryName,
        PageNum: that.ArrearsPaginations.page,
      };
      API.arrearsDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.result.totalForm = res.Data.customerArrearsSumStatementForm;
            that.result.list = res.Data.detail.List;
            that.ArrearsPaginations.total = res.Data.detail.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.ArrearsDetailLoading = false;
        });
    },
    // 导出
    downloadArrearsExcel() {
      var that = this;
      const param = that.searchArrearsData;

      const params = {
        Name: param.Name,
        EntityID: param.EntityID,
        BuyEntityID: param.BuyEntityID,
        StartTime: param.QueryDate[0],
        EndTime: param.QueryDate[1],
        GoodsTypeName: param.GoodsTypeName,
        GoodsName: param.GoodsName,
        CategoryName: param.CategoryName,
      };
      that.downloadLoading = true;
      API.arrearsDetailExcel(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "顾客欠款明细.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function () {
          that.downloadLoading = false;
        });
    },
    // 合计
    getArrearsDetailListSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "TotalAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.TotalAmount)}
              </span>
            );
            break;
          case "PayAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.PayAmount)}
              </span>
            );
            break;
          case "SavingCardDeductionAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.SavingCardDeductionAmount
                )}
              </span>
            );
            break;
          case "SavingCardDeductionLargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.SavingCardDeductionLargessAmount
                )}
              </span>
            );
            break;
          case "OriginMoney":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.OriginMoney)}
              </span>
            );
            break;
          case "FillingMoney":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.FillingMoney)}
              </span>
            );
            break;
          case "ArrearAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.ArrearAmount)}
              </span>
            );
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    // 分页
    handleArrearsPageChange() {
      this.getArrearsList();
    },
  },
};
</script>

<style lang="scss" >
.ReportCustomerArrearsDetail {
}
</style>